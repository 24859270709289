import { FaCheckCircle, FaEllipsisV, FaBan } from "react-icons/fa";
import { Link } from "react-router-dom";
import { calculateQuizPoints } from "./utils";

const QuizList = ({ quizzes, courseId, deleteQuiz, publishQuiz }) => {
  return (
    <ul className="list-group">
      {quizzes.map((quiz) => (
        <li key={quiz._id} className="list-group-item p-0">
          <div className="d-flex justify-content-between align-items-center">
            <style>
              {`
                .quiz-item {
                  transition: background-color 0.2s;
                  border-radius: 5px;
                }
                .quiz-item:hover {
                  background-color: #f0f7fe;
                }
              `}
            </style>
            <Link className="quiz-item w-100 p-3" style={{ color: 'black', textDecoration: 'none' }} to={`/Kanbas/Courses/${courseId}/Quizzes/${quiz._id}`}>
              <div>
                <h5>{quiz.title}</h5>
                <div style={{ fontSize: 14 }}>
                  <span className="me-3">
                    {quiz.availableDate && quiz.availableDate <= new Date()
                      ? "Available until"
                      : quiz.availableDate
                        ? `Not available until ${new Date(quiz.availableDate).toLocaleString()}`
                        : <b>Closed</b>}
                  </span>
                  <span className="me-3">Due {new Date(quiz.dueDate)?.toLocaleString() || "-"}</span>
                  <span className="me-3">{calculateQuizPoints(quiz)} pts</span>
                  <span>{quiz.questions.length} Questions</span>
                </div>
              </div>
            </Link>
            <div className="d-inline-flex justify-content-center align-items-center gap-4 mx-3">
              {quiz.published ? <FaCheckCircle className="text-success" size={20} /> : <FaBan className="text-danger" size={20} />}
              <div class="dropdown">
                <button class="btn btn-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <FaEllipsisV />
                </button>
                <ul class="dropdown-menu p-2 dropdown-menu-dark">
                  <div>
                    <button
                      className="btn btn-sm btn-warning d-flex w-100 justify-content-center"
                      onClick={() => publishQuiz(quiz._id, !quiz.published)}
                    >
                      {quiz.published ? "Unpublish" : "Publish"}
                    </button>
                    <Link
                      to={`/Kanbas/Courses/${courseId}/Quizzes/${quiz._id}/Edit`}
                      className="btn btn-sm btn-primary d-flex w-100 justify-content-center mt-1"
                    >
                      Edit
                    </Link>
                    <Link
                      to={`/Kanbas/Courses/${courseId}/Quizzes/${quiz._id}/Preview`}
                      className="btn btn-sm btn-secondary d-flex w-100 justify-content-center mt-1"
                    >
                      Preview
                    </Link>
                    <button
                      className="btn btn-sm btn-danger d-flex w-100 justify-content-center mt-1"
                      onClick={() => deleteQuiz(quiz._id)}
                    >
                      Delete
                    </button>
                  </div>
                </ul>
              </div>
            </div>
            {/* <div>
              <button
                className="btn btn-sm btn-warning me-2"
                onClick={() => publishQuiz(quiz._id, !quiz.published)}
              >
                {quiz.published ? "Unpublish" : "Publish"}
              </button>
              <Link
                to={`/Kanbas/Courses/${courseId}/Quizzes/${quiz._id}/Edit`}
                className="btn btn-sm btn-primary me-2"
              >
                Edit
              </Link>
              <Link
                to={`/Kanbas/Courses/${courseId}/Quizzes/${quiz._id}/Preview`}
                className="btn btn-sm btn-secondary me-2"
              >
                Preview
              </Link>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => deleteQuiz(quiz._id)}
              >
                Delete
              </button>
            </div> */}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default QuizList;