import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const MultipleChoiceQuestion = ({ question, updateQuestion, deleteQuestion }) => {
    const [title, setTitle] = useState(question.title);
    const [points, setPoints] = useState(question.points);
    const [questionText, setQuestionText] = useState(question.question);
    const [choices, setChoices] = useState(question.choices);
    const [correctAnswer, setCorrectAnswer] = useState(question.correctAnswer);

    const handleSave = () => {
        const updatedQuestion = {
            ...question,
            title,
            points,
            question: questionText,
            choices,
            correctAnswer,
        };
        updateQuestion(updatedQuestion);
    };

    const addChoice = () => {
        setChoices([...choices, ""]);
    };

    const updateChoice = (index, value) => {
        const updatedChoices = [...choices];
        updatedChoices[index] = value;
        setChoices(updatedChoices);
    };

    const deleteChoice = (index) => {
        const updatedChoices = choices.filter((_, i) => i !== index);
        setChoices(updatedChoices);
    };

    return (
        <div>
            <h4>Multiple Choice Question</h4>
            <div className="mb-3">
                <label htmlFor="title" className="form-label">
                    Title
                </label>
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="points" className="form-label">
                    Points
                </label>
                <input
                    type="number"
                    id="points"
                    value={points}
                    onChange={(e) => setPoints(Number(e.target.value))}
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="question" className="form-label">
                    Question
                </label>
                <ReactQuill
                    id="question"
                    value={questionText}
                    onChange={(value) => setQuestionText(value)}
                />
            </div>
            <div className="mb-3">
                <h5>Choices</h5>
                {choices.map((choice, index) => (
                    <div key={index} className="mb-2">
                        <div className="input-group">
                            <div className="input-group-text">
                                <input
                                    type="radio"
                                    checked={correctAnswer === choice}
                                    onChange={() => setCorrectAnswer(choice)}
                                />
                            </div>
                            <input
                                type="text"
                                value={choice}
                                onChange={(e) => updateChoice(index, e.target.value)}
                                className="form-control"
                            />
                            <button
                                className="btn btn-outline-danger"
                                onClick={() => deleteChoice(index)}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
                <button className="btn btn-primary" onClick={addChoice}>
                    Add Choice
                </button>
            </div>
            <button className="btn btn-primary me-2" onClick={handleSave}>
                Save
            </button>
            <button className="btn btn-danger" onClick={() => deleteQuestion(question._id)}>
                Delete
            </button>
        </div>
    );
};

export default MultipleChoiceQuestion;