import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const FillInBlanksQuestion = ({ question, updateQuestion, deleteQuestion }) => {
    const [title, setTitle] = useState(question.title);
    const [points, setPoints] = useState(question.points);
    const [questionText, setQuestionText] = useState(question.question);
    const [blanks, setBlanks] = useState(question.blanks || []);

    const handleSave = () => {
        const updatedQuestion = {
            ...question,
            title,
            points,
            question: questionText,
            blanks,
        };
        updateQuestion(updatedQuestion);
    };

    const addBlank = () => {
        setBlanks([...blanks, ""]);
    };

    const updateBlank = (index, value) => {
        const updatedBlanks = [...blanks];
        updatedBlanks[index] = value;
        setBlanks(updatedBlanks);
    };

    const deleteBlank = (index) => {
        const updatedBlanks = blanks.filter((_, i) => i !== index);
        setBlanks(updatedBlanks);
    };

    return (
        <div>
            <h4>Fill in the Blanks Question</h4>
            <div className="mb-3">
                <label htmlFor="title" className="form-label">
                    Title
                </label>
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="points" className="form-label">
                    Points
                </label>
                <input
                    type="number"
                    id="points"
                    value={points}
                    onChange={(e) => setPoints(Number(e.target.value))}
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="question" className="form-label">
                    Question
                </label>
                <ReactQuill
                    id="question"
                    value={questionText}
                    onChange={(value) => setQuestionText(value)}
                />
            </div>
            <div className="mb-3">
                <h5>Blanks</h5>
                {blanks.map((blank, index) => (
                    <div key={index} className="mb-2">
                        <div className="input-group">
                            <input
                                type="text"
                                value={blank}
                                onChange={(e) => updateBlank(index, e.target.value)}
                                className="form-control"
                            />
                            <button
                                className="btn btn-outline-danger"
                                onClick={() => deleteBlank(index)}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                ))}
                <button className="btn btn-primary" onClick={addBlank}>
                    Add Blank
                </button>
            </div>
            <button className="btn btn-primary me-2" onClick={handleSave}>
                Save
            </button>
            <button className="btn btn-danger" onClick={() => deleteQuestion(question._id)}>
                Delete
            </button>
        </div>
    );
};

export default FillInBlanksQuestion;