const FillInBlanksQuestionPreview = ({ question }) => {
    return (
        <div>
            <h4>{question.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
            {question.blanks.map((_, index) => (
                <div key={index} className="mb-2">
                    <input type="text" className="form-control" />
                </div>
            ))}
        </div>
    );
};

export default FillInBlanksQuestionPreview;