import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as client from "./client";
import { User } from "./client";

export default function Signup() {
    const [error, setError] = useState("");
    const [user, setUser] = useState<User>({
        _id: "",
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        role: "USER"
    });

    const navigate = useNavigate();

    const signup = async () => {
        try {
            await client.signup(user);
            navigate("/Kanbas/Account/Profile");
        } catch (err: any) {
            setError(err.response.data.message);
        }
    };

    return (
        <div className="d-block d-md-block d-grid gap-3 w-100">
            <h1>Signup</h1>
            {error && <div>{error}</div>}
            <input
                className="form-control mt-1"
                placeholder="Username"
                value={user.username}
                onChange={(e) => setUser({ ...user, username: e.target.value })}
            />
            <input
                className="form-control mt-1"
                placeholder="Password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            <button className="btn btn-secondary w-100 mt-1" onClick={signup}>Signup</button>
            <Link to="/Kanbas/Account/Signin">Click Here to Signin</Link>
        </div>
    );
}