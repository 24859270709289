import Nav from "../../Nav";

function HelloWorld() {
    return (
        <div>
            <Nav />
            <h1>Hello World!</h1>;
        </div>
    )
};
export default HelloWorld;