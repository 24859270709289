import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import QuestionEditor from "./QuestionEditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { calculateQuizPoints } from "./utils";

const QuizEditor = () => {
    const { courseId, quizId } = useParams();
    const navigate = useNavigate();
    const [quiz, setQuiz] = useState(null);
    const [activeTab, setActiveTab] = useState("details");

    useEffect(() => {
        const fetchQuiz = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`);
                setQuiz(response.data);
            } catch (error) {
                console.error("Error fetching quiz:", error);
            }
        };
        fetchQuiz();
    }, [quizId]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === "checkbox" ? checked : value;
        setQuiz({ ...quiz, [name]: inputValue });
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`, quiz);
            navigate(`/Kanbas/Courses/${courseId}/Quizzes/${quizId}`);
        } catch (error) {
            console.error("Error saving quiz:", error);
        }
    };

    const handleSaveAndPublish = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`, { ...quiz, published: true });
            navigate(`/Kanbas/Courses/${courseId}/Quizzes`);
        } catch (error) {
            console.error("Error saving and publishing quiz:", error);
        }
    };

    const handleCancel = async () => {
        if (window.location.href.split("?new=")[1] === "true") {
            await axios.delete(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`);
        }
        navigate(`/Kanbas/Courses/${courseId}/Quizzes`);
    };

    if (!quiz) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>{quiz.title}</h2>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === "details" ? "active" : ""}`}
                        onClick={() => setActiveTab("details")}
                    >
                        Details
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === "questions" ? "active" : ""}`}
                        onClick={() => setActiveTab("questions")}
                    >
                        Questions
                    </button>
                </li>
            </ul>
            {activeTab === "details" && (
                <div>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">
                            Title
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={quiz.title}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                            Description
                        </label>
                        <ReactQuill
                            id="description"
                            value={quiz.description}
                            onChange={(value) => setQuiz({ ...quiz, description: value })}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="quizType" className="form-label">
                            Quiz Type
                        </label>
                        <select
                            id="quizType"
                            name="quizType"
                            value={quiz.quizType}
                            onChange={handleInputChange}
                            className="form-select"
                        >
                            <option value="graded_quiz">Graded Quiz</option>
                            <option value="practice_quiz">Practice Quiz</option>
                            <option value="graded_survey">Graded Survey</option>
                            <option value="ungraded_survey">Ungraded Survey</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="points" className="form-label">
                            Points
                        </label>
                        <input
                            type="number"
                            id="points"
                            name="points"
                            value={calculateQuizPoints(quiz)}
                            readOnly
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="assignmentGroup" className="form-label">
                            Assignment Group
                        </label>
                        <select
                            id="assignmentGroup"
                            name="assignmentGroup"
                            value={quiz.assignmentGroup}
                            onChange={handleInputChange}
                            className="form-select"
                        >
                            <option value="quizzes">Quizzes</option>
                            <option value="exams">Exams</option>
                            <option value="assignments">Assignments</option>
                            <option value="project">Project</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                id="shuffleAnswers"
                                name="shuffleAnswers"
                                checked={quiz.shuffleAnswers}
                                onChange={handleInputChange}
                                className="form-check-input"
                            />
                            <label htmlFor="shuffleAnswers" className="form-check-label">
                                Shuffle Answers
                            </label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="timeLimit" className="form-label">
                            Time Limit (Minutes)
                        </label>
                        <input
                            type="number"
                            id="timeLimit"
                            name="timeLimit"
                            value={quiz.timeLimit}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                id="multipleAttempts"
                                name="multipleAttempts"
                                checked={quiz.multipleAttempts}
                                onChange={handleInputChange}
                                className="form-check-input"
                            />
                            <label htmlFor="multipleAttempts" className="form-check-label">
                                Multiple Attempts
                            </label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="showCorrectAnswers" className="form-label">
                            Show Correct Answers
                        </label>
                        <select
                            id="showCorrectAnswers"
                            name="showCorrectAnswers"
                            value={quiz.showCorrectAnswers}
                            onChange={handleInputChange}
                            className="form-select"
                        >
                            <option value="immediately">Immediately</option>
                            <option value="later">Later</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="accessCode" className="form-label">
                            Access Code
                        </label>
                        <input
                            type="text"
                            id="accessCode"
                            name="accessCode"
                            value={quiz.accessCode}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                id="oneQuestionAtATime"
                                name="oneQuestionAtATime"
                                checked={quiz.oneQuestionAtATime}
                                onChange={handleInputChange}
                                className="form-check-input"
                            />
                            <label htmlFor="oneQuestionAtATime" className="form-check-label">
                                One Question at a Time
                            </label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                id="webcamRequired"
                                name="webcamRequired"
                                checked={quiz.webcamRequired}
                                onChange={handleInputChange}
                                className="form-check-input"
                            />
                            <label htmlFor="webcamRequired" className="form-check-label">
                                Webcam Required
                            </label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                id="lockQuestionsAfterAnswering"
                                name="lockQuestionsAfterAnswering"
                                checked={quiz.lockQuestionsAfterAnswering}
                                onChange={handleInputChange}
                                className="form-check-input"
                            />
                            <label htmlFor="lockQuestionsAfterAnswering" className="form-check-label">
                                Lock Questions After Answering
                            </label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="dueDate" className="form-label">
                            Due Date
                        </label>
                        <input
                            type="date"
                            id="dueDate"
                            name="dueDate"
                            value={quiz.dueDate?.toString().split("T")[0] || ""}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="availableDate" className="form-label">
                            Available Date
                        </label>
                        <input
                            type="date"
                            id="availableDate"
                            name="availableDate"
                            value={quiz.availableDate?.toString().split("T")[0] || ""}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="untilDate" className="form-label">
                            Until Date
                        </label>
                        <input
                            type="date"
                            id="untilDate"
                            name="untilDate"
                            value={quiz.untilDate?.toString().split("T")[0] || ""}
                            onChange={handleInputChange}
                            className="form-control"
                        />
                    </div>
                    <button className="btn btn-primary me-2" onClick={handleSave}>
                        Save
                    </button>
                    <button className="btn btn-success me-2" onClick={handleSaveAndPublish}>
                        Save and Publish
                    </button>
                    <button className="btn btn-secondary" onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            )}
            {activeTab === "questions" && (
                <QuestionEditor quiz={quiz} setQuiz={setQuiz} />
            )}
        </div>
    );
};

export default QuizEditor;