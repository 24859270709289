import { useState } from "react";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import TrueFalseQuestion from "./TrueFalseQuestion";
import FillInBlanksQuestion from "./FillInBlanksQuestion";

const QuestionEditor = ({ quiz, setQuiz }) => {
    const [editingQuestionId, setEditingQuestionId] = useState(null);

    const addQuestion = (type) => {
        const newQuestion = {
            _id: Date.now().toString(),
            type,
            title: "",
            points: 5,
            question: "",
            choices: [],
            correctAnswer: "",
            blanks: [],
        };
        setQuiz({ ...quiz, questions: [...quiz.questions, newQuestion] });
        setEditingQuestionId(newQuestion._id);
    };

    const updateQuestion = (updatedQuestion) => {
        setQuiz({
            ...quiz,
            questions: quiz.questions.map((question) =>
                question._id === updatedQuestion._id ? updatedQuestion : question
            ),
        });
        setEditingQuestionId(null);
    };

    const deleteQuestion = (questionId) => {
        setQuiz({
            ...quiz,
            questions: quiz.questions.filter((question) => question._id !== questionId),
        });
    };

    return (
        <div>
            <h3>Questions</h3>
            <div className="mb-3">
                <select
                    className="form-select"
                    onChange={(e) => addQuestion(e.target.value)}
                >
                    <option value="">Select Question Type</option>
                    <option value="multiple_choice">Multiple Choice</option>
                    <option value="true_false">True/False</option>
                    <option value="fill_in_blanks">Fill in the Blanks</option>
                </select>
            </div>
            {quiz.questions.map((question) => (
                <div key={question._id} className="mb-3">
                    {editingQuestionId === question._id ? (
                        <>
                            {question.type === "multiple_choice" && (
                                <MultipleChoiceQuestion
                                    question={question}
                                    updateQuestion={updateQuestion}
                                    deleteQuestion={deleteQuestion}
                                />
                            )}
                            {question.type === "true_false" && (
                                <TrueFalseQuestion
                                    question={question}
                                    updateQuestion={updateQuestion}
                                    deleteQuestion={deleteQuestion}
                                />
                            )}
                            {question.type === "fill_in_blanks" && (
                                <FillInBlanksQuestion
                                    question={question}
                                    updateQuestion={updateQuestion}
                                    deleteQuestion={deleteQuestion}
                                />
                            )}
                        </>
                    ) : (
                        <div>
                            <h4>{question.title}</h4>
                            <p>Type: {question.type}</p>
                            <p>Points: {question.points}</p>
                            <button
                                className="btn btn-sm btn-primary me-2"
                                onClick={() => setEditingQuestionId(question._id)}
                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-sm btn-danger"
                                onClick={() => deleteQuestion(question._id)}
                            >
                                Delete
                            </button>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default QuestionEditor;