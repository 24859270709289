import axios from "axios";
export const BASE_API = process.env.REACT_APP_API_BASE;
export const USERS_API = `${BASE_API}/api/users`;

export interface User {
    _id: string;
    username: string;
    password: string;
    role: string;
    firstName: string,
    lastName: string
};

const usersApi = axios.create({
    baseURL: USERS_API,
    withCredentials: true 
});

export const signin = async (credentials: User) => {
    const response = await usersApi.post(`/signin`, credentials);
    return response.data;
};

export const profile = async () => {
    const response = await usersApi.post(`/profile`);
    return response.data;
};

export const findAllUsers = async () => {
    const response = await usersApi.get(``);
    return response.data;
};

export const createUser = async (user: User) => {
    const response = await usersApi.post(``, user);
    return response.data;
};

export const deleteUser = async (user: User) => {
    const response = await usersApi.delete(`/${user._id}`);
    return response.data;
};

export const updateUser = async (user: any) => {
    const response = await usersApi.put(`/${user._id}`, user);
    return response.data;
};

export const signup = async (user: User) => {
    const response = await usersApi.post(`/signup`, user);
    return response.data;
};

export const signout = async () => {
    const response = await usersApi.post(`/signout`);
    return response.data;
};

// export const signin = async (credentials: User) => {
//     const response = await usersApi.post(`/signin`, credentials);
//     return response.data;
// };

// export const profile = async () => {
//     const response = await usersApi.post(`/profile`);
//     return response.data;
// };

// export const updateUser = async (user: any) => {
//     const response = await usersApi.put(`/${user._id}`, user);
//     return response.data;
// };

// export const findAllUsers = async () => {
//     const response = await usersApi.get(``);
//     return response.data;
// };  