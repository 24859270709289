import { FaCheckCircle, FaEllipsisV, FaPlusCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

function Assignments() {
    const { courseId } = useParams();
    const [assignmentList, setAssignmentList] = useState([] as any[]);

    useEffect(() => {
        const fetchAssignments = async () => {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_BASE}/api/courses/${courseId}/assignments`
            );
            setAssignmentList(data);
        };
        fetchAssignments();
    }, [courseId]);

    return (
        <>
            {/* {< !--Add buttons and other fields here -->} */}
            <div className="control-buttons d-flex gap-2 justify-content-end">
                <input type="text" className="form-control" placeholder="Search for Assignment" />
                <div className="d-flex gap-2">
                    <button className="btn btn-gray">+ Group</button>
                    <button className="btn btn-danger">+ Assignment</button>
                    <button className="btn btn-gray"><FaEllipsisV /></button>
                </div>

            </div>

            <ul className="list-group wd-modules">
                <li className="list-group-item">
                    <div>
                        <FaEllipsisV className="me-2" /> ASSIGNMENTS
                        <span className="float-end">
                            <FaCheckCircle className="text-success" />
                            <FaPlusCircle className="ms-2" /><FaEllipsisV className="ms-2" />
                        </span>
                    </div>
                    <ul className="list-group">
                        {assignmentList.map((assignment) => (
                            <li key={assignment._id} className="list-group-item">
                                <FaEllipsisV className="me-2" />
                                <Link to={`/Kanbas/Courses/${courseId}/Assignments/${assignment._id}`}>{assignment.title}</Link>
                                <span className="float-end">
                                    <FaCheckCircle className="text-success" /><FaEllipsisV className="ms-2" /></span>
                            </li>))}
                    </ul>
                </li>
            </ul>
        </>
    );
}
export default Assignments;