import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TrueFalseQuestion = ({ question, updateQuestion, deleteQuestion }) => {
    const [title, setTitle] = useState(question.title);
    const [points, setPoints] = useState(question.points);
    const [questionText, setQuestionText] = useState(question.question);
    const [correctAnswer, setCorrectAnswer] = useState(question.correctAnswer);

    const handleSave = () => {
        const updatedQuestion = {
            ...question,
            title,
            points,
            question: questionText,
            correctAnswer,
        };
        updateQuestion(updatedQuestion);
    };

    return (
        <div>
            <h4>True/False Question</h4>
            <div className="mb-3">
                <label htmlFor="title" className="form-label">
                    Title
                </label>
                <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="points" className="form-label">
                    Points
                </label>
                <input
                    type="number"
                    id="points"
                    value={points}
                    onChange={(e) => setPoints(Number(e.target.value))}
                    className="form-control"
                />
            </div>
            <div className="mb-3">
                <label htmlFor="question" className="form-label">
                    Question
                </label>
                <ReactQuill
                    id="question"
                    value={questionText}
                    onChange={(value) => setQuestionText(value)}
                />
            </div>
            <div className="mb-3">
                <div className="form-check">
                    <input
                        type="radio"
                        id="true"
                        checked={correctAnswer === "true"}
                        onChange={() => setCorrectAnswer("true")}
                        className="form-check-input"
                    />
                    <label htmlFor="true" className="form-check-label">
                        True
                    </label>
                </div>
                <div className="form-check">
                    <input
                        type="radio"
                        id="false"
                        checked={correctAnswer === "false"}
                        onChange={() => setCorrectAnswer("false")}
                        className="form-check-input"
                    />
                    <label htmlFor="false" className="form-check-label">
                        False
                    </label>
                </div>
            </div>
            <button className="btn btn-primary me-2" onClick={handleSave}>
                Save
            </button>
            <button className="btn btn-danger" onClick={() => deleteQuestion(question._id)}>
                Delete
            </button>
        </div>
    );
};

export default TrueFalseQuestion;