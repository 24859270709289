function ChildStateComponent({ counter, setCounter }:
    {
        counter: number;
        setCounter: (counter: number) => void;
    }) {
    return (
        <div>
            <h2>Child State Component</h2>
            <h3>Counter {counter}</h3>
            <button className="btn btn-primary m-1" onClick={() => setCounter(counter + 1)}>
                Increment</button>
            <button className="btn btn-danger m-1" onClick={() => setCounter(counter - 1)}>
                Decrement</button>
        </div>
    );
}
export default ChildStateComponent;