const TrueFalseQuestionPreview = ({ question }) => {
    return (
        <div>
            <h4>{question.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
            <div className="form-check">
                <input
                    type="radio"
                    id="true"
                    className="form-check-input"
                    name={`question-${question._id}`}
                />
                <label htmlFor="true" className="form-check-label">
                    True
                </label>
            </div>
            <div className="form-check">
                <input
                    type="radio"
                    id="false"
                    className="form-check-input"
                    name={`question-${question._id}`}
                />
                <label htmlFor="false" className="form-check-label">
                    False
                </label>
            </div>
        </div>
    );
};

export default TrueFalseQuestionPreview;