import VariablesAndConstants
    from "./variables/VariablesAndConstants";

function JavaScript() {
    console.log('Hello World!');
    return (
        <div>
            <h1>JavaScript</h1>
            <VariablesAndConstants />
        </div>
    );
}
export default JavaScript