const MultipleChoiceQuestionPreview = ({ question }) => {
    return (
        <div>
            <h4>{question.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
            {question.choices.map((choice, index) => (
                <div key={index} className="form-check">
                    <input
                        type="radio"
                        id={`choice-${index}`}
                        className="form-check-input"
                        name={`question-${question._id}`}
                    />
                    <label htmlFor={`choice-${index}`} className="form-check-label">
                        {choice}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default MultipleChoiceQuestionPreview;