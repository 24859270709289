import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { User } from "./client";
import * as client from "./client";

export default function Signin() {
  const [credentials, setCredentials] = useState<User>({
    _id: "",
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    role: "USER"
  });

  const navigate = useNavigate();

  const signin = async () => {
    try {
      await client.signin(credentials);
      navigate("/Kanbas/Account/Profile");
    } catch (e) {
      alert("Invalid credentials");
      console.log(e);
    }

  };

  useEffect(() => {
    async function fetchProfile() {
      try {
        const account = await client.profile();
        if (account.username) navigate("/Kanbas/Account/Profile");
      } catch (e) {
        console.log(e);
      }
    }
    fetchProfile();
  }, [navigate]);

  return (
    <div>
      <h1>Signin</h1>
      <div className="d-block d-md-block d-grid gap-3 w-100">
        <input
          className="form-control mt-1"
          placeholder="Username"
          value={credentials.username}
          onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
        />
        <input
          className="form-control mt-1"
          placeholder="Password"
          value={credentials.password}
          type="password"
          onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
        />
        <button className="btn btn-primary w-100 mt-1" onClick={signin}>Signin</button>
        <Link to="/Kanbas/Account/Signup">Click Here to Signup</Link>
      </div>
    </div>
  );
}