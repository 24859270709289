import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import MultipleChoiceQuestionPreview from "./MultipleChoiceQuestionPreview";
import TrueFalseQuestionPreview from "./TrueFalseQuestionPreview";
import FillInBlanksQuestionPreview from "./FillInBlanksQuestionPreview";

const QuizPreview = () => {
    const { quizId } = useParams();
    const [quiz, setQuiz] = useState(null);

    useEffect(() => {
        const fetchQuiz = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`);
                setQuiz(response.data);
            } catch (error) {
                console.error("Error fetching quiz:", error);
            }
        };
        fetchQuiz();
    }, [quizId]);

    if (!quiz) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>{quiz.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: quiz.description }}></div>
            {quiz.questions.map((question) => (
                <div key={question._id} className="mb-4">
                    {question.type === "multiple_choice" && (
                        <MultipleChoiceQuestionPreview question={question} />
                    )}
                    {question.type === "true_false" && (
                        <TrueFalseQuestionPreview question={question} />
                    )}
                    {question.type === "fill_in_blanks" && (
                        <FillInBlanksQuestionPreview question={question} />
                    )}
                </div>
            ))}
            <button className="btn btn-primary">Submit</button>
        </div>
    );
};

export default QuizPreview;