//import { courses } from "../../Kanbas/Database";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom";
import { HiMiniBars3 } from "react-icons/hi2";
import CourseNavigation from "./Navigation";
import Modules from "./Modules";
import Home from "./Home";
import Assignments from "./Assignments";
import Piazza from "./Piazza";
import Quizzes from "./Quizzes";
import QuizDetails from "./Quizzes/QuizDetails";
import QuizEditor from "./Quizzes/QuizEditor";
import QuizPreview from "./Quizzes/QuizPreview";

const API_BASE = process.env.REACT_APP_API_BASE;

function Courses({ courses }: { courses: any[]; }) {
    const { courseId, '*': pathId } = useParams();
    //const course = courses.find((course) => course._id === courseId);
    const [course, setCourse] = useState<any>({ _id: "" });

    const COURSES_API = `${API_BASE}/api/courses`;


    useEffect(() => {
        const findCourseById = async (courseId?: string) => {
            const response = await axios.get(
                `${COURSES_API}/${courseId}`
            );
            setCourse(response.data);
        };
        findCourseById(courseId);
    }, [COURSES_API, courseId]);

    return (
        <>
            <div className="d-flex align-items-center">
                <button className="btn d-flex align-items-center" style={{ color: 'red', fontSize: 25 }}><HiMiniBars3 /></button>
                <h1 style={{ fontSize: 16, padding: 0, margin: 0, color: 'red' }}><Link style={{color: 'red'}} to={`/Kanbas/Courses/${courseId}`}>{course?.number} {course?.name}</Link> {
                    (pathId?.split('/') || []).map((p: string, i: number) => (
                        <span key={p}>
                            <span> &gt; </span>
                            <Link style={{color: 'red'}} to={pathId?.split('/').slice(0, i + 1).join('/') || '/'}>{p}</Link>
                        </span>
                    ))
                }</h1>
            </div>

            <hr />

            <div className="d-flex">
                <div className="d-none d-md-block">
                    <CourseNavigation />
                </div>

                <div className="w-100">
                    <Routes>
                        <Route path="/" element={<Navigate to="Home" />} />
                        <Route path="Home" element={<Home />} />
                        <Route path="Modules" element={<Modules />} />
                        <Route path="Piazza" element={<Piazza />} />
                        <Route path="Assignments" element={<Assignments />} />
                        <Route path="Assignments/:assignmentId" element={<h1>Assignment Editor</h1>} />
                        <Route path="Grades" element={<h1>Grades</h1>} />
                        <Route path="Quizzes" element={<Quizzes />} />
                        <Route path="Quizzes/:quizId" element={<QuizDetails />} />
                        <Route path="Quizzes/:quizId/edit" element={<QuizEditor />} />
                        <Route path="Quizzes/:quizId/preview" element={<QuizPreview />} />
                    </Routes>
                </div>
            </div>
        </>
    );
}
export default Courses;