import { Link, useLocation } from "react-router-dom";
function Nav() {
    const { pathname } = useLocation();

    const LabLink = (code: string) => {
        const codeLower = code.toLowerCase();
        const codeUpper = code.toUpperCase();
        return (
            <Link to={`/Labs/${codeLower}`}
                className={`nav-link ${pathname.includes(codeLower) ? "active" : ""}`}>{codeUpper}</Link>
        );
    }

    return (
        <nav className="nav nav-tabs mt-2">
            {LabLink("a3")}
            {LabLink("a4")}
            {LabLink("a5")}
            <Link to="/Kanbas"
                className={`nav-link ${pathname.includes("Kanbas") ? "active" : ""}`}>Kanbas</Link>
            <Link to="/hello"
                className={`nav-link ${pathname.includes("hello") ? "active" : ""}`}>Hello</Link>
        </nav>
    );
}

export default Nav;