import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import QuizList from "./QuizList";

const Quizzes = () => {
    const { courseId } = useParams();
    const navigate = useNavigate();
    const [quizzes, setQuizzes] = useState([]);

    useEffect(() => {
        const fetchQuizzes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE}/api/courses/${courseId}/quizzes`);
                setQuizzes(response.data);
            } catch (error) {
                console.error("Error fetching quizzes:", error);
            }
        };
        fetchQuizzes();
    }, [courseId]);

    const createQuiz = async () => {
        try {
            const newQuiz = {
                title: "New Quiz",
                description: "",
                points: 0,
                availableDate: new Date(),
                untilDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 14),
                dueDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
            };
            const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/courses/${courseId}/quizzes`, newQuiz);
            navigate(`/Kanbas/Courses/${courseId}/Quizzes/${response.data._id}/Edit?new=true`);
        } catch (error) {
            console.error("Error creating quiz:", error);
        }
    };

    const deleteQuiz = async (quizId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`);
            setQuizzes(quizzes.filter((quiz) => quiz._id !== quizId));
        } catch (error) {
            console.error("Error deleting quiz:", error);
        }
    };

    const publishQuiz = async (quizId, published) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`, { published });
            setQuizzes(
                quizzes.map((quiz) => (quiz._id === quizId ? { ...quiz, published } : quiz))
            );
        } catch (error) {
            console.error("Error publishing/unpublishing quiz:", error);
        }
    };

    return (
        <div>
            <h2>Quizzes</h2>
            <button className="btn btn-primary mb-3" onClick={createQuiz}>
                + Quiz
            </button>
            <QuizList
                quizzes={quizzes}
                courseId={courseId}
                deleteQuiz={deleteQuiz}
                publishQuiz={publishQuiz}
            />
        </div>
    );
};

export default Quizzes;