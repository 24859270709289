import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { calculateQuizPoints } from "./utils";

const QuizDetails = () => {
    const { courseId, quizId } = useParams();
    const [quiz, setQuiz] = useState(null);

    useEffect(() => {
        const fetchQuiz = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`);
                setQuiz(response.data);
            } catch (error) {
                console.error("Error fetching quiz:", error);
            }
        };
        fetchQuiz();
    }, [quizId]);



    const publishQuiz = async (published) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_BASE}/api/quizzes/${quizId}`, { published });
            setQuiz({ ...quiz, published });
        } catch (error) {
            console.error("Error publishing/unpublishing quiz:", error);
        }
    };

    if (!quiz) {
        return <div>Loading...</div>;
    }

    return (

        <div>
            <div className="d-flex justify-content-end">
                <button
                    className="btn btn-primary me-2"
                    onClick={() => publishQuiz(!quiz.published)}
                >
                    {quiz.published ? "Unpublish" : "Publish"}
                </button>
                <Link
                    to={`/Kanbas/Courses/${courseId}/Quizzes/${quizId}/Preview`}
                    className="btn btn-secondary me-2"
                >
                    Preview
                </Link>
                <Link
                    to={`/Kanbas/Courses/${courseId}/Quizzes/${quizId}/Edit`}
                    className="btn btn-secondary"
                >
                    Edit
                </Link>
            </div>
            <hr />
            <h2>{quiz.title}</h2>
            {/* <p>Description: {quiz.description}</p> */}
            {/* <p>Description: <span dangerouslySetInnerHTML={{ __html: quiz.description }}></span></p> */}
            <p>Quiz Type: {quiz.quizType}</p>
            <p>Points: {calculateQuizPoints(quiz)}</p>
            <p>Assignment Group: {quiz.assignmentGroup}</p>
            <p>Shuffle Answers: {quiz.shuffleAnswers ? "Yes" : "No"}</p>
            <p>Time Limit: {quiz.timeLimit} Minutes</p>
            <p>Multiple Attempts: {quiz.multipleAttempts ? "Yes" : "No"}</p>
            <p>Show Correct Answers: {quiz.showCorrectAnswers}</p>
            <p>Access Code: {quiz.accessCode || 'N/A'}</p>
            <p>One Question at a Time: {quiz.oneQuestionAtATime ? "Yes" : "No"}</p>
            <p>Webcam Required: {quiz.webcamRequired ? "Yes" : "No"}</p>
            <p>
                Lock Questions After Answering:{" "}
                {quiz.lockQuestionsAfterAnswering ? "Yes" : "No"}
            </p>
            <p>Due Date: {new Date(quiz.dueDate).toLocaleString() || "-"}</p>
            <p>Available Date: {new Date(quiz.availableDate).toLocaleString() || "-"}</p>
            <p>Until Date: {new Date(quiz.untilDate).toLocaleString() || "-"}</p>

        </div>
    );
};

export default QuizDetails;